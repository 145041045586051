<ng-template [ngIf]="!showFullScreenMap">
  <app-navigation
    class="pcoded-navbar"
    [ngClass]="{
      'navbar-collapsed': navCollapsed,
      'theme-horizontal': this.dattaConfig['layout'] === 'horizontal',
      'mob-open': navCollapsedMob
    }"
    (onNavCollapse)="navCollapse()"
    (onNavCollapsedMob)="navMobClick()"
  >
  </app-navigation>
  <app-nav-bar
    class="navbar pcoded-header navbar-expand-lg navbar-light"
    (onNavCollapsedMob)="navMobClick()"
    [isCollapsed]="navCollapsed"
  >
  </app-nav-bar>
  <div class="pcoded-main-container">
    <div class="pcoded-wrapper">
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <app-breadcrumb></app-breadcrumb>
          <div class="main-body">
            <div class="page-wrapper">
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template [ngIf]="showFullScreenMap">
  <router-outlet></router-outlet>
</ng-template>
